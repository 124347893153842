const INITIAL_STATE = {
  alllead: null,
  leadprofile: null,
  commentdata: null,
  leadCount: null,
  leadstatuslist: null,
  courses: null,
  who_am_i: null,
  addCourses: null
};

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "ALL_LEAD": {
      return {
        ...state,
        alllead: action.payload
      };
    }

    case "LEAD_PROFILE": {
      return {
        ...state,
        leadprofile: action.payload
      };
    }
    case "LEAD_COMMENT_LIST": {
      return {
        ...state,
        commentdata: action.payload
      };
    }
    case "ADD_LEAD": {
      return {
        ...state,
        addLead: action.payload
      };
    }
    case "ALL_LEAD_COUNT": {
      return {
        ...state,
        leadCount: action.payload
      };
    }
    case "LEAD_STATUS_LIST": {
      return {
        ...state,
        leadstatuslist: action.payload
      };
    }
    case "Courses": {
      // my code
      return {
        ...state,
        courses: action.payload
      };
    }

    case "Whoami": {
      // my code
      return {
        ...state,
        who_am_i: action.payload // Update the state with courses data
      };
    }
    case "ADD_Course": {
      return {
        ...state,
        addCourses: action.payload
      };
    }

    default: {
      return state;
    }
  }
}
